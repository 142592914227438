import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import RadioField from "./elements/radio"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { postFormData } from "./api/Api"
import { getUser } from "@starberry/myaccount-website-utils"

import axios from "axios"
import * as qs from "query-string"

function MySimpleForm(props) {

  const userData = getUser();

  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)

  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()

  const fields = [
    {
      element: "config",
      formname: "Contact",
      form_type: "contact",
      error_text: "Highlighted fields are required",
      success_text:
        "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "reviewscontact_user",
      email_temp_admin: "reviewscontact_admin",
      email_subject_user: "General Enquiry",
      email_subject_admin: "General Enquiry",
      email_server_func: "contact",
      event_tracking: "contact",
      page_url: "/general-enquiry",
    },
    {
      grpmd: "12",
      label: "Name",
      placeholder: "",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      labelClass: "content_b-18",
      defaultValue: userData?.name || ``
    },
    {
      grpmd: "12",
      label: "Email Address",
      placeholder: "",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",
      defaultValue: userData?.email || ``
    },
    {
      grpmd: "12",
      label: "Telephone Number",
      placeholder: "",
      name: "telephone",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
      labelClass: "content_b-18",
      defaultValue: userData?.mobile || ``
    },
    // {
    //   grpmd: "12",
    //   label: "Line of Enquiry:",
    //   placeholder: "",
    //   name: "type",
    //   event_tracking_decider: "type",
    //   element: "select",
    //   required: true,
    //   values: ['General Enquiry', 'Sales', 'Lettings'],
    //   // class: "md-12 select-input",
    //   labelClass: "content_b-18"
    // },
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Write any information here...",
      name: "message",
      element: "textarea",
      class: "mb-4",
      rows: "3",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      class: "w-md-100 mtop-btn  btn btn-yellow",
      labelClass: "content_b-18",
    },
    {
      text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms & Conditions</a> and <a href="/privacy-policy/">Privacy Policy</a>.',

      element: "html",
      class: "terms",
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== "") {
      const processFromData = async () => {
        formvalues["g_recaptcha_response"] = token

        let formData = new FormData()

        formvalues["extra"] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name,
        })
        formvalues["name"] = formvalues.name
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin

        formData.append("data", JSON.stringify(formvalues))
        const isBlocked = formvalues.email == "markkearney2099@gmail.com";
        if (isBlocked) {
          setThankyou(true);
          return;
        } else {
          postFormData(formData).then(async apiRes => {
            window.grecaptcha.reset()

            // lets send mail
            await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
              method: `POST`,
              mode: "no-cors",
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: qs.stringify(formvalues),
            })

            // const axiosOptions_email = {
            //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
            //   method: "post",
            //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
            //   data: qs.stringify(formvalues),
            // }

            // axios(axiosOptions_email)
            //   .then(response => {
            //     console.log('mail sent!')
            //   })
            //   .catch(err =>
            //     console.log(err)
            //   );
          })
          // const axiosOptions_netlify = {
          //   url: fields[0].page_url,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_netlify)
          //   .then(response => {
          //     console.log('data stored')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );
          const url = typeof window !== "undefined" ? window.location.href : ""
          if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
            fields[0].formname = "Get mortgage help"
          }
          // tracking event
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "formSubmit",
            formType: "form-" + fields[0].event_tracking,
            formId: "form-" + fields[0].event_tracking,
            formName: fields[0].formname,
            formLabel: fields[0].formname,
          })

          setShowerror(false)
          setThankyou(true)

          myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      event.preventDefault()

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g_recaptcha_response"] = token

      setFormvalues(json)

      recaptchaRef.current.execute()

      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
    }
  }

  return (
    <div className="form stbform" id="contactform">
      <div ref={myRef} />

      {showerror && (
        <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}

      {showthankyou && (
        <div className="alert-success">
          <p>{fields[0].success_text}</p>
        </div>
      )}
      <Form
        className="contact-form form-w-label"
        name={fields[0].formname}
        action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="bot-field" />
        {fields.map((field, index) => {
          if ("input" === field.element) {
            return (
              <InputField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                type={field.type}
                fieldClass={field.class}
                placeholder={field.placeholder}
                label={field.label}
                labelClass={field.labelClass}
                required={field.required}
                key={`${field.element}~${index}`}
                pattern={field.patternchk}
                handlechange={handlechange}
                defaultValue={field?.defaultValue}
              />
            )
          }
          if ("select" === field.element) {
            return (
              <SelectField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                required={field.required}
                fieldClass={field.class}
                label={field.label}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                values={field.values}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
                componentprops={props}
              />
            )
          }
          if ("textarea" === field.element) {
            return (
              <TextAreaField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                rows={field.rows}
                fieldClass={field.class}
                label={field.label}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("checkbox" === field.element) {
            return (
              <CheckboxField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("radio" === field.element) {
            return (
              <RadioField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                checked={field.checked}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
                lastchild={field.lastchild}
              />
            )
          }
          if ("html" === field.element) {
            return (
              <HtmlBox
                text={field.text}
                fieldClass={field.class}
                key={`${field.element}~${index}`}
              />
            )
          }
          if ("captcha" === field.element) {
            return (
              <ReCaptchaBox
                fieldClass={field.class}
                captRef={field.captchaRef}
                key={`${field.element}~${index}`}
                handleonVerify={handleonVerify}
              />
            )
          }
          if ("button" === field.element) {
            return (
              <ButtonField
                name={field.name}
                fieldClass={field.class}
                type={field.type}
                value={field.value}
                key={`${field.element}~${index}`}
              />
            )
          }
        })}
      </Form>
    </div>
  )
}

const ContactForm = props => (
  <MySimpleForm officeName={props.officeName} to_email_id={props.to_email_id} />
)

export default ContactForm
